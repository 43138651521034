import { Box, Icon, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { useTranslation } from '@/hooks/useTranslation';

import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { HiSearch } from 'react-icons/hi';
import { useListContext } from './ListContext';
import { useDebounce } from '@uidotdev/usehooks';

const DEBOUNCE_DELAY = 500;

export const ListSearch = () => {
    const router = useRouter();
    const { queryLabel, pageLabel, q } = useListContext();
    const [query, setQuery] = useState(q);
    const { t } = useTranslation(['common']);

    const debouncedQuery = useDebounce(query, DEBOUNCE_DELAY);

    useEffect(() => {
        if (q !== debouncedQuery) {
            router.replace(
                {
                    query: {
                        ...router.query,
                        [queryLabel]: debouncedQuery ?? undefined,
                        [pageLabel]: 1,
                    },
                },
                undefined,
                { scroll: false },
            );
        }
    }, [debouncedQuery, pageLabel, queryLabel, q, router]);

    return (
        <Box mr={4}>
            <InputGroup>
                <InputLeftElement pointerEvents="none">
                    <Icon as={HiSearch} />
                </InputLeftElement>
                <Input
                    onChange={(e) => {
                        setQuery(e.currentTarget.value);
                    }}
                    variant="plain"
                    value={query}
                    placeholder={t('common:search')}
                />
            </InputGroup>
        </Box>
    );
};
